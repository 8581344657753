module.exports = {
  business: {
    title: "Nardi's Order Online Admin",
    name: "Nardi's Order Online",
  },
  theme: {
    color: {
      primary: "rgba(250, 134, 71, 1)",
    },
  },
  firebase: {
    apiKey: "AIzaSyCw0fdzTTlrqsSj9q_51CWq-mXuarpAJT4",
    authDomain: "nardis-order-online.firebaseapp.com",
    databaseURL: "https://nardis-order-online.firebaseio.com",
    projectId: "nardis-order-online",
    storageBucket: "nardis-order-online.appspot.com",
    messagingSenderId: "904686618451",
  },
  printer: {
    enabled: true,
  },
  payment: {
    enabled: true,
  },
  notifications: {
    enabled: true,
    icon: "/assets/icons/android-chrome-384x384.png",
  },
};
